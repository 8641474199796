import React, {useEffect, useState} from "react"
import Helmet from 'react-helmet'
import favicon from "../assets/images/favicon/favicon-32x32.png"
import logo from "../assets/images/Crosssec_logo.svg"

const Layout = ({location, title, children}) => {
    const [isLoading, setIsLoading] = useState(true);
    const rootPath = `${__PATH_PREFIX__}/`
    const isRootPath = location.pathname === rootPath

    useEffect(() => {
        setTimeout(() => {
            setIsLoading(false)
        }, 100);
    }, [])

    return (
        <div className={`global-wrapper ${isLoading ? "loading" : "loaded"}`} data-is-root-path={isRootPath}>
            <Helmet>
                <link rel="icon" type="image/png" sizes="32x32" href={favicon} />
                <meta name="robots" content="index, follow" />
                <meta name="format-detection" content="telephone=no" />
            </Helmet>
            <main>{children}</main>
            <footer>
                <a className="logo" href="https://crosssec.com">
                    <img src={logo} alt="crosssec logo" />
                </a>
            </footer>
        </div>
    )
}

export default Layout
